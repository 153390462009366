.container-placeholder{
    margin:auto;
    width : 400px;
    height : 100px;
    margin-top: 5%;
}
.main-container{
    margin-top:5px;
    background-color: rgb(230, 228, 228);
    padding: 10px;
    max-height: 700px;
    overflow: hidden;
}
.sub-container{
    padding :10px;
    padding-left: 20px;
    background-color: white;
    height: 660px;
    padding-bottom: 20px;
}
.icon{
    text-align: right;
    cursor: pointer;
}
.user-list{
    max-height: 500px;
    overflow: auto;
}